import { ErrorHandler, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiException } from '../eform-api';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  readonly error$ = new Subject<any>();

  constructor() {
    console.log('ErrorService was injected!');
  }
}

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private errorService: ErrorService, private router: Router) { }

  handleError(error): void {
    if (error instanceof ApiException) {
      const problem = JSON.parse(error.response);
      if(problem.status === 403) {
        this.router.navigate(['/']);
      }
      this.errorService.error$.next(problem);
    } else {
      console.error(error);
    }
  }
}
