import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  // Needed for hash routing
  {
    path: 'code',
    pathMatch: 'full', redirectTo: '',
  },
  {
    path: ':projectId/_settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [MsalGuard]
  },
  {
    path: '_settings',
    loadChildren: () => import('./platform/platform.module').then(m => m.PlatformModule), canActivate: [MsalGuard]
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule), canActivate: [MsalGuard]
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
