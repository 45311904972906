import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormFieldModel, FormModel } from 'src/app/eform-api';

@Injectable({
  providedIn: 'root'
})
export class FormConfigureService {
  public readonly isConfiguring$ = new Subject<boolean>();
  public readonly configuringModels$ = new Subject<{ form: FormModel, field: FormFieldModel; }>();
  public readonly newField$ = new Subject();
  public readonly removeField$ = new Subject();
  public readonly disabledState$ = new Subject();

  private configringPanelVisible = false;

  constructor() { }

  toggleConfiguringPanel(): boolean {
    this.configringPanelVisible = !this.configringPanelVisible;
    this.isConfiguring$.next(this.configringPanelVisible);
    return this.configringPanelVisible;
  }

  closeConfiguringPanel(): void {
    this.configringPanelVisible = false;
    this.isConfiguring$.next(this.configringPanelVisible);
  }

  get isConfiguring(): boolean {
    return this.configringPanelVisible;
  }

  setConfiguringModels(form: FormModel, field: FormFieldModel): void {
    this.configuringModels$.next({ form, field });
  }

  newField(): void {
    this.newField$.next();
  }

  removeField(): void {
    this.removeField$.next();
  }

  disabledChange(): void {
    this.disabledState$.next();
  }
}
