import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { HostingEnvironmentSettings } from './app/eform-api';

if (environment.production) {
  enableProdMode();
}

// Discover the settings store on the host environments
fetch(environment.apiUrl + '/api/hostingenvironmentsettings').then(async res => {
  const hostEnvSettings = await res.json();
  console.log(hostEnvSettings);

  // Bootstrap the Angular application
  platformBrowserDynamic([
    { provide: HostingEnvironmentSettings, useValue: hostEnvSettings },
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
