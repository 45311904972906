import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_ENDPOINT_USERS = 'https://graph.microsoft.com/v1.0/users';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  profile;
  initials;
  userName;
  data;
  readonly loggedIn$ = new Subject<boolean>();
  private readonly destroying$ = new Subject<void>();
  readonly userName$ = new Subject<any>();

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    // private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private sanitizer: DomSanitizer,
  ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          // msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.HANDLE_REDIRECT_END),
        takeUntil(this.destroying$)
      )
      .subscribe((result) => {
        console.log('msalBroadcastService.msalSubject$', result);

        if (this.msalLoggedIn) {
          this.getProfile();
        }
      }, (error) => {
        console.error(error);
      });

    // Just for trigger the msalBroadcastService subject
    this.authService.handleRedirectObservable().subscribe().unsubscribe();
  }

  private get msalLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  private getProfile(): any {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.initials = this.getInitials(this.profile?.displayName);
        this.loggedIn$.next(true);
        this.userName$.next(this.profile?.displayName);
      });
  }

  public getUserList(value: string, top: number = null, properties: string[] = null): any {
    const query: string[] = [];

    if (!!value) {
      query.push(`$search="displayName:${value}"OR "mail:${value}"`);
    }
    if (!!top) {
      query.push(`$top=${top}`);
    }

    if (!!properties) {
      query.push(`$select=${properties.join(',')}`);
    } else {
      query.push('$select=id,displayName,mail,userPrincipalName');
    }

    const url = `${GRAPH_ENDPOINT_USERS}?${query.join('&')}`;
    return this.http.get(url, { headers: { ConsistencyLevel: 'eventual' } });
  }

  public getUserInfo(uid): any {
    const URL = `${GRAPH_ENDPOINT_USERS}/${uid}?"&$select=id,displayName,mail,userPrincipalName`;
    return this.http.get(URL, { headers: { ConsistencyLevel: 'eventual' } });
  }

  public getInitials(name: string, glue: boolean = true): any {
    const initials = name.split(' ').map(n => n.charAt(0));
    if (glue) { return initials.join(''); }
    return initials;
  }

  public getUserImage(uid: string): any {
    return this.http.get(GRAPH_ENDPOINT_USERS + '/' + uid + '/photo/$value',
      { headers: { ConsistencyLevel: 'eventual' } })
      .pipe(map(blob => {
        const urlCreator = window.URL;
        return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
      }));
  }

  public login(): void {
    // if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //   this.authService
    //     .loginPopup({ ...this.msalGuardConfig.authRequest })
    //     .subscribe(() => this.checkAccount());
    // } else {
    //   this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest });
    // }
  }

  public logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.destroying$.next(null);
    this.destroying$.complete();
  }
}
