import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { HostingEnvironmentSettings } from './eform-api';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel, message): void {
  // console.log(logLevel + '-' + message);
}

export function MSALInstanceFactory(settings: HostingEnvironmentSettings): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: settings.spaClientId,
      authority : "https://login.microsoftonline.com/" + settings.spaTenantId,
      redirectUri: settings.spaRedirectUri,
      postLogoutRedirectUri: settings.spaPostLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(settings: HostingEnvironmentSettings): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', ['user.read.all']);
  protectedResourceMap.set('/api', [settings.spaWebApiScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}
