import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject, Subscription } from 'rxjs';
import { FormConfigureService } from './main/common/form-configure.service';
import { ErrorService } from './services/error.service';
import { UserService } from './services/user.service';
import { ProjectFormModel, ProjectsClient, HostingEnvironmentSettings } from 'src/app/eform-api';
import { ActivatedRoute, Router  } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('notification', { static: false }) template?: TemplateRef<{}>;

  isIframe = false;
  loggedIn = false;
  subscriptions = new Subscription();

  private readonly destroying$ = new Subject<void>();
  isConfiguring: boolean;
  showErrorDetail = false;
  projects: any;
  appInsights: ApplicationInsights;

  constructor(
    public user: UserService,
    private configureService: FormConfigureService,
    private notification: NzNotificationService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private projectsClient: ProjectsClient,
    private temHostSettings: HostingEnvironmentSettings,
  ) {
    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.temHostSettings.applicationInsightsKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    appInsights.loadAppInsights();
    console.log(this.temHostSettings.applicationInsightsKey);
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.subscriptions.add(this.user.loggedIn$.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    }));

    this.subscriptions.add(this.configureService.isConfiguring$.subscribe(
      value => {
        this.isConfiguring = value;
      }
    ));

    this.subscriptions.add(this.errorService.error$.subscribe(
      problem => {
        this.notification.template(this.template, {
          nzDuration: 0,
          nzClass: 'notification',
          nzData:
            { title: 'Error', message: problem.title || '', detail: problem.detail || '' }
        });
      }
    ));

    this.subscriptions.add(
      this.route.params
        .pipe(
          switchMap(params => {
            return this.projectsClient.getUserAdminedProjects(environment.apiVersion);
          })
        ).subscribe(projects => {
          console.log(projects);
          this.projects = projects;
        })
    );
  }

  toggleErrorDetail(): void {
    this.showErrorDetail = !this.showErrorDetail;
  }

  ngOnDestroy(): void {
    this.destroying$.next(null);
    this.destroying$.complete();
    this.subscriptions.unsubscribe();
  }
}
